import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import { Link as RouterLink } from "gatsby";
import {
    component,
    bookButton,
    enquireButton,
} from "./BookButtonMobile.module.scss";
import { useBookingModalContext } from "../../providers/BookingModalProvider";
const BookButtonMobile = () => {
    const { openBookingModal } = useBookingModalContext();

    return (
        <Zoom in>
            <Box role="presentation" className={component}>
                <Button
                    className={bookButton}
                    size="small"
                    variant="contained"
                    onClick={openBookingModal}
                >
                    Book Now
                </Button>
                <Button
                    className={enquireButton}
                    component={RouterLink}
                    to="/contact"
                    size="small"
                    variant="contained"
                >
                    Enquire
                </Button>
            </Box>
        </Zoom>
    );
};

export default BookButtonMobile;
