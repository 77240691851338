import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "gatsby";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import Logo from "../Logo/Logo";
import { logo, navBar } from "./NavBar.module.scss";
import FlyoutPanelMobile from "../FlyoutPanelMobile/FlyoutPanelMobile";
import MobileActions from "../MobileActions/MobileActions";
import { isBrowser } from "../../utils";
import { useBookingModalContext } from "../../providers/BookingModalProvider";

const navLinks = [
    { name: "Home", to: "/" },
    { name: "Treatments", to: "/treatments" },
    { name: "Contact us", to: "/contact" },
];

export const isHomePage = () =>
    isBrowser() &&
    !navLinks.some(
        (nav) => nav.to !== "/" && window.location.href.includes(nav.to)
    );

export const isSelectedPage = (path: string) =>
    (isBrowser() && window.location.href.includes(path) && path !== "/") ||
    (path === "/" && isHomePage());

const NavBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerAction = () => {
        setIsDrawerOpen(false);
    };

    const { openBookingModal } = useBookingModalContext();

    return (
        <HideOnScroll>
            <AppBar className={navBar}>
                <Container maxWidth="lg" disableGutters>
                    <Toolbar>
                        <Hidden smUp>
                            <IconButton
                                sx={{ paddingRight: "40px" }}
                                color="secondary"
                                edge="start"
                                onClick={() => setIsDrawerOpen(true)}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Logo className={logo} />
                        <Hidden smUp>
                            <MobileActions />
                        </Hidden>
                        <Hidden smDown>
                            {navLinks.map((navLink) => (
                                <Link
                                    className={`${
                                        isSelectedPage(navLink.to)
                                            ? "Active-Page"
                                            : ""
                                    }`}
                                    key={navLink.name}
                                    variant="button"
                                    underline="none"
                                    component={RouterLink}
                                    to={navLink.to}
                                >
                                    {navLink.name}
                                </Link>
                            ))}
                            <Hidden mdDown>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={openBookingModal}
                                >
                                    Book Now
                                </Button>
                            </Hidden>
                        </Hidden>
                    </Toolbar>
                </Container>
                <FlyoutPanelMobile
                    isOpen={isDrawerOpen}
                    onDrawerAction={handleDrawerAction}
                    navLinks={navLinks}
                />
            </AppBar>
        </HideOnScroll>
    );
};

export default NavBar;
