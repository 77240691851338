import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Link from "@mui/material/Link";
import { action, actions } from "./MobileActions.module.scss";

const MobileActions = () => (
    <div className={actions}>
        <div className={action}>
            <Link className="Action-Link" href="tel:+447714337897">
                <PhoneIcon />
            </Link>
        </div>
        <div className={action}>
            <Link
                className="Action-Link"
                href="https://api.whatsapp.com/send?phone=447714337897"
            >
                <WhatsAppIcon />
            </Link>
        </div>
    </div>
);

export default MobileActions;
