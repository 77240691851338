export const isBrowser = () => typeof window !== "undefined";

export const getSelectedFilterTitle = (fallbackText: string) => {
    if (!isBrowser() || !window.location.href) {
        return fallbackText;
    }

    const elements = window.location.href.split("/");

    return elements.length ? elements[elements.length - 1] : fallbackText;
};

export const uppercaseFirstLetter = (string: string) =>
    string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
