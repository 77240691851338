import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import React from "react";
import { isBrowser } from "../../utils";

interface Props {
    children: React.ReactElement;
}

const HideOnScroll = ({ children }: Props) => {
    const trigger = useScrollTrigger({
        target: isBrowser() ? window : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

export default HideOnScroll;
