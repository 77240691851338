import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { DefaultProps } from "../types/types";
import { img } from "./Logo.module.scss";

const Logo = ({ className }: DefaultProps<HTMLElement>) => (
    <div className={className}>
        <StaticImage
            className={img}
            placeholder="blurred"
            loading="eager"
            width={100}
            src="../../images/logo/lc_logo_black.png"
            alt="Laser Collective Liverpool Logo"
        />
    </div>
);

export default Logo;
