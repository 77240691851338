import React from "react";

const TreatwellLogo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="42"
        height="42"
        viewBox="0 0 512 512"
    >
        <path
            fill="#FF5D3A"
            d="M185.012 318.258c9.958 3.35 19.347 1.228 29.86-1.61-4.06 7.934-9.225 13.398-15.315 17.901-13.464 9.955-28.61 12.232-44.556 8.108-17.035-4.406-28.574-19.27-28.99-38.131-.572-25.967-.281-51.953-.358-77.93-.006-1.977-.001-3.953-.001-6.549h-25.387c1.416-2.46 1.927-4.153 3.048-5.177a11661.87 11661.87 0 0 1 57.343-52.028c1.197-1.079 2.964-1.526 5.311-2.683v6.466c0 39.635-.065 79.27.087 118.904.02 4.926.67 9.992 1.958 14.74 2.36 8.695 7.898 14.868 17 17.989z"
        />
        <path
            fill="#FF5D3B"
            d="M241.639 268.375c-6.658-14.934-13.267-29.477-19.528-44.169-1.498-3.516-3.412-4.522-7.086-4.456-12.8.23-25.607.089-38.769.089v-18.94h4.554c23.5 0 47 .082 70.5-.087 3.558-.026 5.205 1.112 6.608 4.36 12.8 29.627 25.859 59.142 38.592 88.797 1.021 2.377 1.06 5.929.053 8.297-5.396 12.707-11.236 25.227-17.021 37.766-.64 1.389-1.902 2.492-2.876 3.728-1.101-1.289-2.583-2.407-3.247-3.89-10.597-23.692-21.089-47.43-31.78-71.495z"
        />
        <path
            fill="#FF5D3A"
            d="M376.829 296.504c-.097 1.803-.04 3.358-.61 4.633a3770.015 3770.015 0 0 1-17.948 39.51c-.556 1.206-1.802 2.093-2.728 3.128-.908-1.052-2.138-1.961-2.678-3.177-20.319-45.735-40.574-91.497-60.831-137.26-.258-.581-.424-1.204-.85-2.436 14.193 0 27.94-.121 41.677.188 1.376.031 3.212 2.446 3.96 4.14 13.37 30.263 26.606 60.586 40.008 91.274z"
        />
        <path
            fill="#FF5E3B"
            d="M391.602 248.411c-2.045-16.49-6-32.031-12.568-47.264h42.45l-27.621 54.334-1.543-.471c-.21-2.056-.421-4.111-.718-6.599z"
        />
    </svg>
);

export default TreatwellLogo;
