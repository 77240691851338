import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "gatsby";
import { component } from "./BreadCrumbs.module.scss";
import { isBrowser } from "../../utils";

const capitalizeFirstLetter = (path: string) =>
    path.charAt(0).toUpperCase() + path.slice(1);

const getNameFromPath = (path: string) => {
    const removedHyphens = path.split("-");

    return removedHyphens.reduce<string>((acc, name) => {
        // eslint-disable-next-line no-param-reassign
        acc += `${capitalizeFirstLetter(name)} `;
        return acc;
    }, "");
};

const getCrumbName = (path: string) => {
    if (!path) {
        return "";
    }

    return getNameFromPath(path);
};

const BreadCrumbs = () => {
    const location = isBrowser() ? window.location : undefined;

    const paths = location
        ? location.pathname.split("/").filter((path) => path !== "")
        : [];

    return (
        <Breadcrumbs maxItems={2} className={component} aria-label="breadcrumb">
            {paths.length ? (
                <Link
                    component={RouterLink}
                    underline="hover"
                    color="inherit"
                    to="/"
                >
                    Home
                </Link>
            ) : null}
            {paths.map((path, index) => {
                const isCurrentPage = index === paths.length - 1;
                const link =
                    isCurrentPage && location ? location.pathname : `/${path}`;

                return (
                    <Link
                        color={isCurrentPage ? "text.primary" : "inherit"}
                        aria-current={isCurrentPage ? "page" : undefined}
                        component={RouterLink}
                        key={path}
                        underline="hover"
                        to={link}
                    >
                        {getCrumbName(path)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;
