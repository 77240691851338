import React, { createContext, useContext, useState, useCallback } from "react";

interface ThemeState {
    isOpen: boolean;
    openBookingModal: () => void;
    bookAndCloseModal: () => void;
}

const BookingModalContext = createContext<ThemeState>({
    isOpen: false,
    openBookingModal: () => {},
    bookAndCloseModal: () => {},
});

export const useBookingModalContext = () => useContext(BookingModalContext);

export const BookingModalProvider = ({
    children,
}: React.ComponentProps<"div">) => {
    const [open, setOpen] = useState(false);

    const openBookingModal = () => {
        setOpen(true);
    };

    const bookAndCloseModal = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <BookingModalContext.Provider
            value={{
                isOpen: open,
                openBookingModal,
                bookAndCloseModal,
            }}
        >
            {children}
        </BookingModalContext.Provider>
    );
};
