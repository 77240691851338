import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import createTheme from "@mui/material/styles/createTheme";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        h1_Lato: React.CSSProperties;
        productFilterTitle: React.CSSProperties;
        treatmentLink: React.CSSProperties;
        contactDetails: React.CSSProperties;
        productFilterParent: React.CSSProperties;
        productFilterChild: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        h1_Lato?: React.CSSProperties;
        productFilterTitle?: React.CSSProperties;
        treatmentLink: React.CSSProperties;
        contactDetails: React.CSSProperties;
        productFilterParent: React.CSSProperties;
        productFilterChild: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        h1_Lato: true;
        productFilterTitle: true;
        treatmentLink: true;
        contactDetails: true;
        productFilterParent: true;
        productFilterChild: true;
    }
}

const theme = responsiveFontSizes(
    createTheme({
        typography: {
            fontFamily: ["Montserrat Variable", "sans-serif"].join(","),
            h1_Lato: {},
            treatmentLink: {},
            contactDetails: {},
            productFilterTitle: {},
            productFilterParent: {},
            productFilterChild: {},
        },
        palette: {
            primary: {
                main: "#cab388",
                light: "#d4c29f",
                dark: "#8d7d5f",
            },
            secondary: {
                main: "#39464e", // older gray "#39464e"
                light: "#b7b7b7",
                dark: "#747474",
            },
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: { backgroundColor: "white" },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: { color: "black" },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: { borderRadius: "25px", padding: "4px 16px" },
                },
            },
        },
    })
);

theme.typography.h1_Lato = {
    fontSize: "1.8rem",

    fontWeight: "100",
    color: "#454545", // light black
    [theme.breakpoints.up("md")]: {
        fontSize: "2.9rem",
    },
};

theme.typography.productFilterTitle = {
    fontSize: "1rem",
    fontWeight: "400",
    color: "#454545", // light black
    [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
    },
};

theme.typography.productFilterParent = {
    fontSize: "1rem",
    fontWeight: "400",
    color: "#454545", // light black
};

theme.typography.productFilterChild = {
    fontSize: "1rem",
    fontWeight: "300",
    color: "#454545", // light black
};

theme.typography.h1 = {
    fontSize: "1.8rem",
    fontWeight: "300",
    color: "#cab388",
    [theme.breakpoints.up("md")]: {
        fontSize: "2.9rem",
    },
};

theme.typography.h2 = {
    fontSize: "1.4rem",
    fontWeight: "300",
    color: "#cab388",
    [theme.breakpoints.up("md")]: {
        fontSize: "1.8rem",
    },
};

theme.typography.h3 = {
    fontSize: "1.1rem",
    fontWeight: "400",
    color: "#454545",
    [theme.breakpoints.up("md")]: {
        fontSize: "1.3rem",
    },
};

theme.typography.body1 = {
    fontSize: "1.1rem",
    fontWeight: "300",

    [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
    },
};

theme.typography.h4 = {
    fontSize: "1.2rem",
    fontWeight: "300",
};

theme.typography.h6 = {
    fontWeight: "300",
};

theme.typography.body2 = {
    fontSize: "1.1rem",
};

theme.typography.treatmentLink = {
    fontSize: "1.2rem",
    fontWeight: "300",
    [theme.breakpoints.up("md")]: {
        fontSize: "1.4rem",
    },
};

theme.typography.contactDetails = {
    fontSize: "1rem",
    fontWeight: "300",
    [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
    },
};

export default theme;
