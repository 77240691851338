import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import SocialMedia from "../SocialMedia/SocialMedia";
import { footerLinks, component } from "./Footer.module.scss";

const Copyright = () => (
    <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.lasercollective.co.uk/">
            lasercollective.co.uk
        </Link>{" "}
        {new Date().getFullYear()}.
    </Typography>
);

const Footer = () => (
    <footer className={component}>
        <Container maxWidth="lg">
            <Typography variant="h6" align="center" gutterBottom>
                Find us on social media
            </Typography>

            <SocialMedia />
            <div className={footerLinks}>
                <Link href="/">Privacy policy</Link>
                <Link href="/">Terms and conditions</Link>
                <Link href="/">Sitemap</Link>
            </div>
            <Copyright />
        </Container>
    </footer>
);

export default Footer;
