import React from "react";

import Link from "@mui/material/Link";
import { Link as RouterLink } from "gatsby";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { DefaultProps } from "../types/types";
import SocialMedia from "../SocialMedia/SocialMedia";
import {
    invisible,
    drawerHeader,
    mobileLink,
    listStyles,
} from "./FlyoutPanelMobile.module.scss";
import Logo from "../Logo/Logo";
import { useBookingModalContext } from "../../providers/BookingModalProvider";

export const DrawerHeader = ({
    onDrawerAction,
}: {
    onDrawerAction: (isOpen: boolean) => void;
}) => (
    <div className={drawerHeader}>
        <div className={invisible} />
        <Logo />
        <IconButton onClick={() => onDrawerAction(false)} size="large">
            <CloseIcon color="primary" />
        </IconButton>
    </div>
);

interface Props extends DefaultProps<HTMLElement> {
    isOpen: boolean;
    onDrawerAction: (isOpen: boolean) => void;
    navLinks: { name: string; to: string }[];
}

const FlyoutPanelMobile = ({ isOpen, onDrawerAction, navLinks }: Props) => {
    const { openBookingModal } = useBookingModalContext();

    return (
        <SwipeableDrawer
            open={isOpen}
            anchor="left"
            onOpen={() => onDrawerAction(true)}
            onClose={() => onDrawerAction(false)}
        >
            <DrawerHeader onDrawerAction={onDrawerAction} />
            <Divider />
            <List className={listStyles}>
                {navLinks.map((link) => (
                    <ListItem className={mobileLink} button key={link.name}>
                        <Link
                            variant="button"
                            underline="none"
                            component={RouterLink}
                            to={link.to}
                            onClick={() => onDrawerAction(false)}
                        >
                            {link.name}
                        </Link>
                    </ListItem>
                ))}
                <ListItem className={mobileLink} button>
                    <Link
                        variant="button"
                        underline="none"
                        onClick={() => {
                            onDrawerAction(false);
                            openBookingModal();
                        }}
                    >
                        Book Now
                    </Link>
                </ListItem>
            </List>
            <Divider />
            <SocialMedia />
        </SwipeableDrawer>
    );
};
export default FlyoutPanelMobile;
